<template>
    
        <div class="coverpage" v-if="single && !isLoading" :style="(single.portada) ? { backgroundImage: 'url(' + single.portada.data.thumbnails[5].url + ')' } : null">
            <div class="gradient" />
            <div class="content">
                <div class="container small">
                    <span class="date-tag" v-if="!this.$route.params.category">Estreno Destacado</span>  
                    <h1 v-if="(this.$route.params.category == 'noticias' || !this.$route.params.category) && single.titulo">{{single.titulo}}</h1>
                </div>  
            </div>
            <vue-plyr v-if="single.trailer && this.$route.params.category == 'peliculas'">
                <div class="plyr__video-embed">
                    <iframe
                    :src="parseLink(single.trailer)"
                    :poster="single.portada.data.full_url"
                    allowfullscreen
                    allowtransparency
                    allow="autoplay"
                    ></iframe>
                </div>
            </vue-plyr>
        </div>

        <div class="coverpage loading" v-else >
        </div>
    
</template>

<script>

export default{
    name: 'Coverpage',
    props: {
        single: [],
        isLoading: false
    },
    methods:{
        parseLink: function(url){

            let link = new URL(url);
            const domain = link.hostname;

            let id

            if(domain == 'youtu.be'){
                id= link.pathname.replace('/', '')
            }else{
                id= link.searchParams.get("v")
            }

            return 'https://www.youtube.com/embed/'+id
        }
    },
}

</script>

<style scoped>

    /* ----- SKELETON -----*/
    .container.small{
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .coverpage{
        padding-top: 42% !important;
        background: #cfcfcf;
        margin: 0;
        padding: 0;
        position: relative;
        background-size: cover;
        background-position: center;
    }

    .coverpage .content{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
    }

    .coverpage .gradient{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 50%;
        width: 100%;
        background-image: linear-gradient(to top,rgba(0,0,0,.7) 10%,rgba(0,0,0,0));
    }

    .date-tag {
        margin-bottom: 16px;
    }

    .plyr{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .plyr{
        --plyr-color-main: #fcaf17;
    }

    
    @media(max-width: 768px){
        .coverpage{
            height: 300px;
        }
    }

</style>