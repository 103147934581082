<template>
    <section class="single">
        <Coverpage :single="item" :isLoading="isLoading" />
        
        <div class="container small" v-if="this.$route.params.category == 'noticias'">
            <div class="art-header">
                <div class="date-tag" :class="(isLoading) ? 'loading' : null" >
                    <span>{{item.fecha}}</span>
                </div>
                <div class="share">
                    <h4 v-if="item.length > 0">Compartilo</h4>
                    <a :href="whatsappLink" class="social-icon-container" target="_blank" :class="(isLoading) ? 'loading' : null">
                        <div class="social-icon whatsapp"></div>
                    </a>
                    <a :href="twLink" class="social-icon-container" target="_blank" :class="(isLoading) ? 'loading' : null">
                        <div class="social-icon twitter"></div>
                    </a>
                    <a :href="fbLink" class="social-icon-container" target="_blank" :class="(isLoading) ? 'loading' : null">
                        <div class="social-icon facebook"></div>
                    </a>
                </div>
            </div>
            
            <div class="art-body" v-if="item.contenido && !isLoading" v-html="item.contenido">
            </div>
            
            <div class="art-body" v-else>
                <div class="art-body-placeholder" v-for="index in 5" :key=index :class="(isLoading) ? 'loading' : null"></div>
            </div>
        </div>

        <Spinner v-show="isLoading"/>

        <Ficha v-if="!isLoading && relatedMovie !== null" :showSectionTitle="(this.$route.params.category == 'noticias') ? true : false" :movie="relatedMovie"/>
        
        <div class="container small" v-if="!isLoading && relatedNews.data && relatedNews.data.length > 0">
            <div class="section-header">
                <h2 class="section-title">Lo más leído</h2>
            </div>
            <div class="section-content">
                <div class="col l4 s6" v-for="newsItem in relatedNews.data" :key="newsItem.id">
                    <NewsItem :item="newsItem" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Spinner from '@/components/Spinner.vue';
import Coverpage from '@/components/Coverpage.vue'
import Ficha from '@/components/Ficha.vue'
import NewsItem from '@/components/NewsItem.vue'
import router from '../router'

export default {
    name: 'Single',
    components: {
        Coverpage,
        Ficha,
        NewsItem,
        Spinner
    },
    data(){
        return{
            isLoading: true,
            item: Promise,
            whatsappLink: null,
            twLink: null,
            fbLink: null,
            relatedMovie: null,
            relatedNews: []
        }
    },
    mounted (){
        (async () => {
            this.loadSingle()
        })()
    },
    methods: {
        loadSingle: async function(){
            this.isLoading = true
            this.item = []
            this.relatedMovie = null
            this.relatedNews = []

            // get config
            this.config = await this.getConfig()
            
            let category = this.$route.params.category
            let slug = this.$route.params.slug

            this.item = await this.getItem(category, slug)

            if( Object.keys(this.item).length > 0 ){

                this.setHeader(category, this.item)

                this.whatsappLink = "whatsapp://send?text=" + window.location.href
                this.twLink = "https://twitter.com/intent/tweet?url=" + window.location.href
                this.fbLink = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href

                this.countViews(category, this.item.id)
            
                if(category == "noticias" && this.item.pelicula_relacionada !== null){
                    this.relatedMovie = await this.getItem('peliculas', this.item.pelicula_relacionada.slug)
                }

                if(category == "peliculas"){
                    this.relatedMovie = this.item
                }

                if(category == "noticias"){
                    var exclude = this.item.id
                    var filter = 'filter[id][nin]='+exclude
                }

                this.relatedNews = await this.getItems('noticias', 6, filter+'&sort=-visualizaciones')
                
                this.isLoading = false
            }else{
                router.push('/404')
            }
        }
    },
    watch:{
        $route (to, from){
            this.loadSingle()
        }
    }
}
</script>

<style scoped>
.art-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.art-body-placeholder-bold{
    display: block;
    height: 36px;
    width: 100%;
    margin-bottom: 8px;
}

.art-body-placeholder{
    display: block;
    height: 24px;
    width: 100%;
    margin-bottom: 8px;
}

.art-body-placeholder:last-child{
    width: 50%;
}

.art-img{
    width: 100%;
    height: 616px;
    background: #cfcfcf;
}
</style>
