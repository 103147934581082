<template>
    <div class="home">
        <section class="article dark">
            <div class="container small">
                <h2 class="section-title" v-if="showSectionTitle">Película relacionada</h2>
                <div class="art-content">
                    <div class="row">
                         <div class="left">    
                            <div class="poster">
                                <MovieItem :showPlay="false" :showTitle="false" :showDateTag="false" :movie="movie"/>
                            </div>
                        </div>
                        <div class="right">
                            <h1 v-if="movie.titulo">{{ trimTitle(movie.titulo) }}</h1>
                            <div class="genre-tags" v-if="movie.genero.length > 0">
                                <div class="genre-tag" v-for="genreTag,genreIndex in movie.genero" :key=genreIndex.id><span>{{genreTag.generos_id.nombre}}</span></div>
                            </div>
                            <div class="date-social">
                                <div class="date-tag" v-if="movie.fecha_local != null">{{movie.fecha_local}}</div>
                                <div class="share" v-if="this.category == 'peliculas'">
                                    <h4 class="hide-on-small">Compartilo</h4>
                                    <a :href="whatsappLink" class="social-icon-container" target="_blank">
                                        <div class="social-icon whatsapp"></div>
                                    </a>
                                    <a :href="twLink" class="social-icon-container" target="_blank">
                                        <div class="social-icon twitter"></div>
                                    </a>
                                    <a :href="fbLink" class="social-icon-container" target="_blank">
                                        <div class="social-icon facebook"></div>
                                    </a>
                                </div>
                            </div>    
                            <div class="movie-info">
                                <div class="sinopsis" v-if="movie.sinopsis">
                                    <p>{{movie.sinopsis}}</p>
                                </div>
                                <div class="technical-info">
                                    <div class="left">
                                        <p v-if="movie.elenco != null"><b>Elenco:</b><br>{{movie.elenco}}</p>
                                        <p v-if="movie.dirigida_por != null"><b>Dirigida por:</b><br>{{movie.dirigida_por}}</p>
                                    </div>
                                    <div class="middle">
                                        <p v-if="movie.duracion != null"><b>Duración:</b><br>{{movie.duracion}}</p>
                                        <p v-if="movie.calificacion != null"><b>Calificación:</b><br>{{movie.calificacion}}</p>
                                    </div>
                                    <div class="right">
                                        <p v-if="movie.distribuidora != null"><b>Distribuidora:</b><br>{{movie.distribuidora.nombre}}</p>
                                    </div>
                                </div>
                            </div>    
                        </div>   
                    </div>
                    <div class="movie-info mobile">
                        <div class="sinopsis" v-if="movie.sinopsis">
                            <p>{{movie.sinopsis}}</p>
                        </div>
                        <div class="technical-info">
                            <div class="left">
                                <p v-if="movie.elenco != null"><b>Elenco:</b><br>{{movie.elenco}}</p>
                                <p v-if="movie.dirigida_por != null"><b>Dirigida por:</b><br>{{movie.dirigida_por}}</p>
                            </div>
                            <div class="right">
                                <p v-if="movie.duracion != null"><b>Duración:</b><br>{{movie.duracion}}</p>
                                <p v-if="movie.calificacion != null"><b>Calificación:</b><br>{{movie.calificacion}}</p>
                                <p v-if="movie.distribuidora != null"><b>Distribuidora:</b><br>{{movie.distribuidora.nombre}}</p>
                            </div>
                        </div>
                    </div>     
                </div>
            </div>    
        </section>
    </div>
</template>

<script>
import MovieItem from '@/components/MovieItem.vue'

export default {
    name:'Ficha',
    components:{
        MovieItem
    },
    data(){
        return{
            movies: [],
            isLoading: true,
            item: Promise,
            whatsappLink: null,
            twLink: null,
            fbLink: null,
            category: null,
        }
    },
    mounted(){
        this.category = this.$route.params.category
        this.whatsappLink = "whatsapp://send?text=" + window.location.href
        this.twLink = "https://twitter.com/intent/tweet?url=" + window.location.href
        this.fbLink = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    },
    methods: {
        trimTitle: function(title){
            if(title.length > 30){
                return title.substring(0, 30) + '...'
            }else{
                return title
            }    
        },
    },
    props:{
        showSectionTitle:{
            type: Boolean
        },
        movie:{
            type: Object
        },
        title:{
            type: String
        }
    }
}    
</script>

<style scoped>

    .article .art-content{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .mobile{
        display: none;
    }

    .left{
        width: 30%;
        flex-shrink: 0;
    }

    .right{
        padding-left: 16px;
        width: 70%;
    }

    .date-tag{
        margin-bottom: 0 !important;
        height: max-content;
    }

    p,h1{
        margin-bottom: 16px;
    }

    .genre-tags{
        display: flex;
        color: #fcaf17;
        margin-bottom: 24px;
        overflow-x: auto;
    }

    .genre-tag{
        border: 1px solid #fcaf17;
        border-radius: 32px;
        padding: 3px 12px;
        margin-right: 8px; 
        display: flex;
        align-items: center;
    }

    .genre-tag span{
        font-size: 12px;
        width: max-content;
    }

    .movie-info .technical-info{
        display: flex;
    }

    .movie-info .technical-info .left{
        width: 60%;
    }

    .movie-info .technical-info .middle,.movie-info .technical-info .right{
        width: 20%;
    }

    .movie-info .technical-info b{
        font-size: 14px;
    }

    .date-social{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .date-social h4{
        color: white;
    }

    .section-title{
      margin-bottom: 24px !important;
    }

    @media(max-width: 768px){
        .movie-info{
            display: none;
        }

        .mobile{
            display: block;
        }

        .movie-info .technical-info .left,.movie-info .technical-info .right{
        width: 50%;
        }

        .art-content .row{
            align-items: center;
        }

        .poster{
            margin-bottom: 16px;
        }
    }
</style>
